<template>
  <div class="signature_box">
    <van-loading
      class="loading_box"
      :color="themecolors"
      :text-color="themecolors"
      v-if="isHttpData"
    />
    <div v-else>
      <!-- kv -->
      <div class="kv_box">
        <img v-if="sign_kv" :src="sign_kv" alt="" srcset="" />
      </div>
      <!-- 内容 -->
      <div class="content_box">
        <div class="signature_content" v-if="!showSignature">
          <div class="signature_title">线下签到</div>
          <el-input v-model="input" placeholder="请输入姓名或手机号"></el-input>
          <div class="signature_btn" @click="getSignatureClick">签 到</div>
        </div>
        <div class="signatre_success" v-else>
          <div class="success_box">
            <img src="@/assets/successful.svg" alt="" srcset="" />
            <div class="success_tip">恭喜您签到成功!</div>
            <!-- <div class="success_tip">某某公司。。。。。</div> -->
          </div>
        </div>
      </div>
      <div class="return_btn">
        <div class="go_home" @click="gohome">
          <img src="@/assets/icon/home.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Loading from "@/components/loading/loading";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      isHttpData: true, //页面加载动画
      showSignature: false,
      input: "",
      sign_kv: "",
    };
  },
  computed: {
    ...mapState(["themecolors"]),
  },
  mounted() {
    if (localStorage.getItem("site_status") == "true") {
      this.showSignature = true;
    } else {
      this.showSignature = false;
    }
    this.getView();
  },
  methods: {
    // 详情
    getView() {
      this.$store
        .dispatch("get_user_register_info", {
          site_url: this.$route.query.site_url,
        })
        .then((res) => {
          if (res.data.code == 200) {
            document.title = "线下签到";
            this.isHttpData = false;
            this.sign_kv = res.data.data.sign_img;
          }
        });
    },
    // 首页跳转
    gohome() {
      window.location.href = `/event/${this.$route.query.site_url}`;
    },
    // 签到
    getSignatureClick() {
      //   const isNumber = /^\d+$/.test(this.input);
      if (this.input == "") {
        return this.$toast("请输入签名");
      }
      //   else {
      //     if (isNumber && this.input.length != 11) {
      //       return this.$toast("请输入正确的手机号");
      //     }
      //   }
      this.$store
        .dispatch("user_sign", {
          key: this.input,
          site_id: this.$route.query.site_id,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.showSignature = true;
            localStorage.setItem("site_status", true);
          } else {
            this.$toast(res.data.message);
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.signature_box {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #f7f8fa;
}
.loading_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.signature_title {
  width: 100%;
  margin: 0 auto;
  font-size: 0.2rem;
  text-align: center;
  margin-bottom: 50px;
}
.kv_box {
  width: 100%;
  img {
    width: 100%;
    height: 100%;
  }
}

.content_box {
  z-index: 11;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 1.8rem;
  width: 94%;
  left: 3%;
  box-shadow: 0 3px 10px #ededed;
  background: #fff;
  border-radius: 6px;
  padding: 50px 20px 50px 20px;
}
.signature_content {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
/deep/.el-input {
  width: 92%;
}
/deep/.el-input__inner:focus {
  border-color: #00ccb0;
}
.signature_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto 0;
  padding: 5px;
  font-size: 16px;
  background-color: #00ccb0;
  color: #fff;
  width: 92%;
  border-radius: 8px;
  height: 40px;
  line-height: 32px;
}
.signatre_success {
  display: flex;
  align-items: center;
  /* flex-direction: column; */
  justify-content: center;
  width: 100%;
  margin: 40px 0;

  .success_box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
      width: 200px;
    }
  }
}
.success_tip {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  white-space: nowrap;
  margin-top: 20px;
  font-size: 0.18rem;
  color: #333;
  font-weight: 700;
}
.return_btn {
  position: fixed;
  bottom: 18%;
  right: 3%;
  .go_home {
    width: 0.35rem;
    height: 0.35rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    font-size: 14px;
    color: #fff;
    background-color: #00ccb0;
    img {
      width: 0.18rem;
    }
  }
}
</style>
